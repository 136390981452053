import React, { useContext } from "react"
import i18n from "i18next"
import Slider from "react-slick"
import { LoadingContext } from "../../components/context/LoadingContext"
import image1 from "../../images/products/ImageAgileTeam.png"
import { Link } from "gatsby"

const AgileTeam = ({}) => {
  const { windowWidth } = useContext(LoadingContext)

  return (
    <div className={"mb-20 desktop:pb-0 desktop:p-24 mobile:w-full mobile:pl-4 mobile:pr-4 p-8 tablet:px-24 text-left bigdesktop:px-32 "}>
      {windowWidth >= 1025 ? (
        <div className="flex contentApply mr-24">
          <div className="mt-10">
            <div className=" ">
              <img src={image1} alt="...." className="" />
            </div>
          </div>

          <div className="contentAgileTeam">
            <div className="eventRightTimeLineMobile">
              <p className="titleOurServices">
                {i18n.t("pageExceptionProducts.agileTeam.title")}
              </p>
            </div>

            <p className="textExclusiveProducts">
              {i18n.t("pageExceptionProducts.agileTeam.text")}
            </p>
            <div className="contentViewMoreProducts">
              <Link to={"/agile-team"}>
                <button
                  className="mobile:mt-4 bottomViewMoreProducts"
                  style={{ alignSelf: "left", width: "12rem", height: "3rem" }}
                >
                  <p className="textBottomViewMoreProducts">
                    {i18n.t("pageExceptionProducts.agileTeam.button")}
                  </p>
                </button>
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <div className="">
          <div className="ml-4 mr-8">
            <div className="mb-4">
              <div className=" ">
                <img src={image1} alt="...." className="" />
              </div>
            </div>
            <div className="eventRightTimeLineMobile">
              <p className="titleOurServices text-gray-600 my-8">
                {i18n.t("pageExceptionProducts.agileTeam.title")}
              </p>
            </div>

            <p className="text_our_experienceMobile my-4">
              {i18n.t("pageExceptionProducts.agileTeam.text")}
            </p>

            <div className="my-4">
              <Link to={"/agile-team"}>
                <button
                  className="mobile:mt-4 bottomViewMoreProducts w-full"
                  style={{ alignSelf: "left", width: "12rem", height: "3rem" }}
                >
                  <p className="textBottomViewMoreProducts">
                    {i18n.t("pageExceptionProducts.agileTeam.button")}
                  </p>
                </button>
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default AgileTeam
