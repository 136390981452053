import React, { Fragment, useState, useContext } from "react"
import image1 from "../../images/products/placeholder.png"
import { LoadingContext } from "../context/LoadingContext"
import { Link } from "gatsby"
import i18n from "i18next"
import xElement from "../../images/servicesHunting/icons/xDoble.png"
import imageTalentATS from "../../images/products/placeholder.png"

const BannerExclusiveProducts = ({ bannerImageURL }) => {
  const [dinamicHeightColBanner, setDinamicHieghtColbanner] = useState(0)
  const { windowWidth } = useContext(LoadingContext)

  return (
    <Fragment>
      <div className="container-banner-mainProducts">
        <div className="shared-banner-container whyusdiv">
          {windowWidth >= 1025 ? (
            <div className="flex mobile:flex-col-reverse desktop:flex-row bannerPrincipal">
              <div
                ref={el => {
                  if (el) {
                    const resizeObserver = new ResizeObserver(entries => {
                      setDinamicHieghtColbanner(entries[0].contentRect.height)
                    })
                    resizeObserver.observe(el)
                  }
                }}
                className="shared-banner-content-btn m-4 desktop:mt-4 mobile:mt-16"
              >
                <div className="text-left tablet:pl-24 text-white mb-32 tablet:mb-0">
                  {i18n.language === "en" ? (
                    <p className="text-56 desktop:text-64 bigdesktop:text-80 font-byom-bold">
                      E<span className="text-blue-100">x</span>ceptional
                      <br />
                      <span className="text-relativo">Products</span>
                    </p>
                  ) : (
                    <p className="text-56 desktop:text-64 bigdesktop:text-80 font-byom-bold">
                      <span>Productos</span>
                      <br />
                      <span className="text-relativo">
                        E<span className="text-blue-100">x</span>cepcionales
                      </span>
                    </p>
                  )}
                </div>
              </div>
              <div className="shared-banner-imageHome mobile:ml-0">
                <div className="bannerExperienceContentStyle">
                  <img
                    src={bannerImageURL}
                    alt="Imagen PopUp"
                    className="imgBannerExperience"
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="flex mobile:flex-col-reverse desktop:flex-row bannerPrincipalMobile">
              <div
                ref={el => {
                  if (el) {
                    const resizeObserver = new ResizeObserver(entries => {
                      setDinamicHieghtColbanner(entries[0].contentRect.height)
                    })
                    resizeObserver.observe(el)
                  }
                }}
                className="ml-4 mb-32 text-white tablet:mb-0 m-4 desktop:mt-4 mobile:mt-16"
              >
                {i18n.language === "en" ? (
                  <p className="text-56 desktop:text-64 bigdesktop:text-80 font-byom-bold">
                    E<span className="text-blue-100">x</span>ceptional
                    <br />
                    <span className="text-relativo">Products</span>
                  </p>
                ) : (
                  <p className="text-56 desktop:text-64 bigdesktop:text-80 font-byom-bold">
                    <span>Productos</span>
                    <br />
                    <span className="text-relativo">
                      E<span className="text-blue-100">x</span>cepcionales
                    </span>
                  </p>
                )}
              </div>
              <div className=" shared-banner-imageHomeMobile mobile:ml-0">
                <div className="bannerContentStyleMobile">
                  <img
                    src={bannerImageURL}
                    alt="Imagen PopUp"
                    className="hidden tablet:block imgBannerExperience"
                  />
                </div>
              </div>
            </div>
          )}

          {/* Talent ATS Section */}
          {windowWidth >= 1025 ? (
            <div className="flex desktop:gap-16 bigdesktop:gap-24 mt-12 desktop:mt-20 p-8 tablet:px-20 text-left bigdesktop:justify-between">
              <div className="contentWhyUs">
                <div className="eventRightTimeLineMobile">
                  <p className="titleOurServices">
                    {i18n.t("pageExceptionProducts.talentAts.title")}
                  </p>
                </div>

                <p className="textExclusiveProducts">
                  {i18n.t("pageExceptionProducts.talentAts.text")}
                </p>
                <div className="contentViewMoreProducts">
                  <Link to={"/TalentATS"}>
                    <button
                      className="mobile:mt-4 bottomViewMoreProducts"
                      style={{
                        alignSelf: "left",
                        width: "12rem",
                        height: "3rem",
                      }}
                    >
                      <p className="textBottomViewMoreProducts">
                        {i18n.t("pageExceptionProducts.talentAts.button")}
                      </p>
                    </button>
                  </Link>
                </div>
              </div>
              <div className="">
                <img src={imageTalentATS} alt="...." className="" />
                <div className="flex w-full justify-end ">
                  <img className="pr-40" src={xElement} alt="xElement" />
                </div>
              </div>
            </div>
          ) : (
            // mobile
            <div className="desktop:flex desktop:flex-wrap gap-8 desktop:px-6 mt-20 pb-16 desktop:pb-0 ml-4 backgroundTalentProducts">
              <div className="ml-4 mr-8">
                <div className="mt-10">
                  <img src={imageTalentATS} alt="...." className="" />
                  <div className="flex w-full justify-center ">
                    <img className="w-1/3" src={xElement} alt="xElement" />
                  </div>
                </div>
                <div className="eventRightTimeLineMobile mt-10 mb-5">
                  <p className="titleOurServices">
                    {i18n.t("pageExceptionProducts.talentAts.title")}
                  </p>
                </div>

                <p className="text_our_experienceMobile">
                  {i18n.t("pageExceptionProducts.talentAts.text")}
                </p>

                <div className="mt-4">
                  <Link to={"/TalentATS"}>
                    <button
                      className="mobile:mt-4 w-full bottomViewMoreProducts"
                      style={{
                        alignSelf: "left",
                        width: "12rem",
                        height: "3rem",
                      }}
                    >
                      <p className="textBottomViewMoreProducts ">
                        {i18n.t("pageExceptionProducts.talentAts.button")}
                      </p>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  )
}

BannerExclusiveProducts.defaultProps = {
  flag: true,
  clase: "shared-banner-footer banner-text-white mt-8",
  style: {},
}

export default BannerExclusiveProducts
