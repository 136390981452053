import React, { useContext } from "react"
import i18n from "i18next"
import Slider from "react-slick"
import { LoadingContext } from "../../components/context/LoadingContext"
import image1 from "../../images/products/ImageAgileTeam.png"
import { Link } from "gatsby"

const Conection = ({}) => {
  const { windowWidth } = useContext(LoadingContext)

  return (
    <div
      className={`pb-20 bigdesktop:p-32 w-full h-full ${
        windowWidth >= 1025
          ? "backgroundConection"
          : "backgroundConectionMobile"
      } mobile:px-4`}
    >
      {windowWidth >= 1025 ? (
        <div className="flex">
          <div className="contentConections ">
            <p className="textConection mb-5 pt-10 ml-20 bigdesktop:mt-0">
              {i18n.t("pageExceptionProducts.internet.text")}
            </p>
            <div className="contentViewMoreProducts ml-20">
              {/* missing route */}
              <Link to="/">
              <button
                className="mobile:mt-4 bottomViewMoreProducts"
                style={{ alignSelf: "left", width: "12rem", height: "3rem" }}
              >
                <p className="textBottomViewMoreProducts">
                  {i18n.t("pageExceptionProducts.internet.button")}
                </p>
              </button>
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <div className="pt-16">
          <div className="mt-24 tablet:ml-4 tablet:mr-8">
            <p className="textConectionMobile mb-4">
              {i18n.t("pageExceptionProducts.internet.text")}
            </p>
          {/* missing route */}
            <div className="">
              <Link to="/">
              <button
                className="mobile:mt-4 bottomViewMoreProducts w-full"
                style={{ alignSelf: "left", height: "3rem" }}
              >
                <p className="textBottomViewMoreProducts">
                  {i18n.t("pageExceptionProducts.internet.button")}
                </p>
              </button>
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Conection
