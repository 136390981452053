import React, { useContext } from "react"
import i18n from "i18next"
import Slider from "react-slick"
import { LoadingContext } from "../../components/context/LoadingContext"
import image1 from "../../images/products/imageTransformation.png"
import check from "../../images/products/check.png"
import { Link } from "gatsby"
import imageAgileTransformation from "../../images/products/imageTransformation.png"
import xElement from "../../images/servicesHunting/icons/xDoble.png"

const AgileTransformation = ({}) => {
  const { windowWidth } = useContext(LoadingContext)

  const imagesArr = [
    {
      texto: i18n.t("pageExceptionProducts.agileTransformation.items.item1"),
    },
    {
      texto: i18n.t("pageExceptionProducts.agileTransformation.items.item3"),
    },
    {
      texto: i18n.t("pageExceptionProducts.agileTransformation.items.item2"),
    },
    {
      texto: i18n.t("pageExceptionProducts.agileTransformation.items.item4"),
    },
  ]

  return (
    <div className={"whyusdiv pb-24 p-8 tablet:px-24  text-left bigdesktop:pl-32"}>
      {windowWidth >= 1025 ? (
        <div className="flex mt-20 contentApply mr-24">
          <div className="mt-8">
            <div className="eventRightTimeLineMobile">
              <p className="titleOurServices ">
                {i18n.t("pageExceptionProducts.agileTransformation.title")}
              </p>
            </div>

            <p className="textExclusiveProducts">
              {i18n.t("pageExceptionProducts.agileTransformation.text")}
            </p>

            <div className="checkContent">
              {imagesArr.map((item, i) => {
                return (
                  <div className="flex ">
                    <div className="flex">
                      <button className="checkTransformation">
                        <img src={check} alt="...." className="m-auto" />
                      </button>
                      <p className="textCheck">{item.texto}</p>
                    </div>
                  </div>
                )
              })}
            </div>

            <div className="contentViewMoreProducts">
              <Link to={"/transformation"}>
                <button
                  className="mobile:mt-4 bottomViewMoreProducts"
                  style={{ alignSelf: "left", width: "12rem", height: "3rem" }}
                >
                  <p className="textBottomViewMoreProducts">
                    {i18n.t("pageExceptionProducts.agileTransformation.button")}
                  </p>
                </button>
              </Link>
            </div>
          </div>
          <div className="  mt-4 ml-12 ">
            <img src={imageAgileTransformation} alt="...." className="" />
            <div className="flex w-full justify-end ">
              <img className="pr-40" src={xElement} alt="xElement" />
            </div>
          </div>
        </div>
      ) : (
        // Mobile
        <div className="desktop:flex desktop:flex-wrap desktop:px-6 mt-20 ml-4">
          <div className="mr-8">
            <div className="mt-10">
              <img src={image1} alt="...." className="" />
              <div className="flex w-full justify-center ">
                <img className="w-1/3" src={xElement} alt="xElement" />
              </div>
            </div>
            <div className="eventRightTimeLineMobile my-10 desktop:my-0">
              <p className="titleOurServices">
                {i18n.t("pageExceptionProducts.agileTransformation.title")}
              </p>
            </div>

            <p className="text_our_experienceMobile mt-4">
              {i18n.t("pageExceptionProducts.agileTransformation.text")}
            </p>

            {imagesArr.map((item, i) => {
              return (
                <div className="flex mt-4 ">
                  <div className=" flex">
                    <button className="checkTransformation">
                      <img src={check} alt="...." className="m-auto" />
                    </button>
                    <p className="my-auto text-gray-500 font-avenir-light">
                      {item.texto}
                    </p>
                  </div>
                </div>
              )
            })}

            <div className="">
              <Link to={"/transformation"}>
                <button
                  className="mobile:mt-4 bottomViewMoreProducts w-full desktop:w-fit"
                  style={{ alignSelf: "left", width: "12rem", height: "3rem" }}
                >
                  <p className="textBottomViewMoreProducts">
                    {i18n.t("pageExceptionProducts.agileTransformation.button")}
                  </p>
                </button>
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default AgileTransformation
