import React, { useContext } from "react"
import i18n from "i18next"
import Slider from "react-slick"
import { LoadingContext } from "../../components/context/LoadingContext"

import image1 from "../../images/products/JS.png"
import image2 from "../../images/products/HTMLTech.png"
import image4 from "../../images/products/CSSTech.png"
import image5 from "../../images/products/AngularTech.png"
import image6 from "../../images/products/ReactNativeTech.png"
import image7 from "../../images/products/Ionic.png"
import image8 from "../../images/products/ReactRedux.png"
import image9 from "../../images/products/Mysql.png"
import image10 from "../../images/products/oracle.png"
import image11 from "../../images/products/xamarinTech.png"
import image12 from "../../images/products/postgresSql.png"
import image13 from "../../images/products/kotlin.png"

const Technologys = ({}) => {
  const { windowWidth } = useContext(LoadingContext)

  const imagesArr = [
    {
      image: image1,
      id: "1",
    },
    {
      image: image2,
      id: "1",
    },
    {
      image: image4,
      id: "1",
    },
    {
      image: image5,
      id: "1",
    },
    {
      image: image6,
      id: "1",
    },
    {
      image: image7,
      id: "2",
    },
    {
      image: image8,
      id: "1",
    },
    {
      image: image9,
      id: "1",
    },
    {
      image: image10,
      id: "2",
    },
    {
      image: image11,
      id: "1",
    },
    {
      image: image12,
      id: "1",
    },
    {
      image: image13,
      id: "2",
    },
  ]

  return (
    <div className={"p-10 tablet:p-20 mt-16 tablet:px-24 text-left bigdesktop:px-32"}>
      {windowWidth >= 1025 ? (
        <>
          <div className="flex contentApply mr-24">
            <div className="countTextCountMobile widthLogosServices">
              <p className="textAboutUs">
                {i18n.t("pageExceptionProducts.popularTechnologies.subtitle")}
              </p>
              <p className="titleOurServices">
                {i18n.t("pageExceptionProducts.popularTechnologies.title")}
              </p>
            </div>
            <div className="ml-16">
              <div className="flex marginTechnologys">
                {imagesArr.slice(0, 4).map((item, i) => {
                  return (
                    <div className="contentTargetProducts ">
                      <div className="m-auto">
                        <img
                          src={item.image}
                          alt="...."
                          className={
                            item.id == 2
                              ? "imgCardExpertCertification"
                              : "imgCardExpert"
                          }
                        />
                      </div>
                    </div>
                  )
                })}
              </div>

              <div className="flex mt-8 ml-24">
                {imagesArr.slice(4, 8).map((item, i) => {
                  return (
                    <div className="contentTargetProducts ">
                      <div className="m-auto">
                        <img
                          src={item.image}
                          alt="...."
                          className={
                            item.id == 2
                              ? "imgCardExpertCertification"
                              : "imgCardExpert"
                          }
                        />
                      </div>
                    </div>
                  )
                })}
              </div>

              <div className="flex marginTechnologys mt-8 mb-12">
                {imagesArr.slice(8, 13).map((item, i) => {
                  return (
                    <div className="contentTargetProducts ">
                      <div className="m-auto">
                        <img
                          src={item.image}
                          alt="...."
                          className={
                            item.id == 2
                              ? "imgCardExpertCertification"
                              : "imgCardExpert"
                          }
                        />
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="">
            <div className="countTextCountMobile">
              <p className="subTitleOurServices">
                {i18n.t("pageExceptionProducts.popularTechnologies.subtitle")}
              </p>
              <p className="titleOurServices">
                {i18n.t("pageExceptionProducts.popularTechnologies.title")}
              </p>
            </div>

            <div className="mt-8">
              {imagesArr.map((item, i) => {
                return (
                  <div className={`w-full h-full mb-8 flex ${i%2!==0? "justify-start": "justify-end"}`}>
                    <div className="contentTargetProductsMobile flex justify-center items-center">
                      <img
                        src={item.image}
                        alt="...."
                        className= ""
                        // {
                        //   item.id == 2
                        //     ? "imgCardExpertCertificationMobile"
                        //     : "imgCardExpertResponsive"
                        // }
                      />
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default Technologys
