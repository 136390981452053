import React from "react"
import columnImageRight from "../images/products/BannerProducts.png"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { withNamespaces } from "react-i18next"
import BannerExclusiveProducts from "../components/Products/BannerExclusiveProducts"
import AgileTeam from "../components/Products/AgileTeam"
import Conection from "../components/Products/Conection"
import AgileTransformation from "../components/Products/AgileTransformation"
import Technologys from "../components/Products/Technologys"

const ExclusiveProducts = () => {
  return (
    <Layout>
      <SEO
        title={"ExclusiveProducts | Exsis Digital Angels"}
        description={"Nearshore Agile Teams | Specialists in Tech Talent"}
      />
      <BannerExclusiveProducts
        bannerImageURL={columnImageRight}
      ></BannerExclusiveProducts>
      <AgileTeam />
      <Conection />
      <AgileTransformation />
      <Technologys />
    </Layout>
  )
}
export default withNamespaces()(ExclusiveProducts)